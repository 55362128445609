import { CaretRight } from "@phosphor-icons/react";

type Props = {
  onOpenSection: (id: string) => void;
};

export const SettingSections = ({ onOpenSection }: Props) => {

  const settingsoptions = [
    {
      id: '1',
      header: 'Account Settings',
    },
    {
      id: '2',
      header: 'Security',
    },
    {
      id: '3',
      header: 'Customization',
    },
  ]

  const handleSection = (id : string) =>{
    onOpenSection(id);
  }
  
  return (
    <div className="space-y-6">
      {settingsoptions.map((item,index)=>{
        return(<div key={index} onClick={()=> handleSection(item.id)} className={`flex items-center justify-between py-4 xs:py-8 text-[1rem] xs:text-[1.5rem] cursor-pointer ${
          index === settingsoptions.length - 1 ? '' : 'border-b border-primary/80'
        }`}>
          {item.header} <CaretRight size={24} />
        </div>)
      })}
    </div>
  );
};
